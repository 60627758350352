import { useCallback, useEffect, useState } from "react";
import { useGetAllEquipmentTypes } from "hooks/queries/useEquipmentType";
import { useOutletContext } from "react-router";
import CustomButton from "components/buttons/CustomButton";
import DropdownField from "components/inputs/DropdownField";
import { Box, Typography } from "@mui/material";
import SpinningCircle from "components/spinners/SpinningCircle";
import colors from "config/theme/colors";
import MassImportStepperModal from "../../../components/modals/MassImportStepperModal";
import UploadFileStep from "./Steps/UploadFileStep";
import DataValidationStep from "./Steps/DataValidationStep";
import {
  useGetBulkEquipmentTemplate,
  useImportBulkEquipment,
} from "hooks/queries/useBulkImportEquipment";
import { useAlert } from "hooks/useAlert";
import EquipmentMassImportErrorModal from "./EquipmentMassImportErrorModal";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const warningTitle = "Το αρχείο που επιλέξατε είναι κενό";

const EquipmentMassImport = () => {
  const { showError, showSuccess } = useAlert();
  const { selectedAsset } = useOutletContext();
  const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [errorsResponse, setErrorsResponse] = useState([]);
  const [submitData, setSubmitData] = useState(null);
  const [selectedExportType, setSelectedExportType] = useState(null);
  const [errorsModalOpen, setErrorsModalOpen] = useState(false);
  const [errorsModalTitle, setErrorsModalTitle] = useState("");
  const {
    data: equipmentTypes,
    isLoading: equipmentTypesLoading,
    error: equipmentTypesError,
  } = useGetAllEquipmentTypes({
    pageNumber: null,
    pageSize: null,
    assetCategoryId: selectedAsset?.assetCategory?.id,
  });
  const selectedEquipmentTypeId = selectedEquipmentType?.id;
  const selectedEquipmentTypeName = selectedEquipmentType?.label;
  const selectedAssetName = selectedAsset?.name;
  const {
    data: templateData,
    isLoading: templateLoading,
    isError: templateError,
    refetch: refetchTemplateData,
  } = useGetBulkEquipmentTemplate({
    equipmentTypeId: selectedEquipmentTypeId,
    assetId: selectedAsset?.id,
  });
  const { mutate: importBulkEquipment } = useImportBulkEquipment({
    config: {
      onError: (error) => {
        showError(`Η εισαγωγή απέτυχε: ${error.message}`);
      },
      onSuccess: (res) => {
        const { result } = res;
        const { errorsResponse } = result || {};

        if (errorsResponse && errorsResponse.length > 0) {
          setErrorsModalTitle("Η εισαγωγή ολοκληρώθηκε με προβλήματα");
          setErrorsModalOpen(true);
          setErrorsResponse(errorsResponse);
        } else {
          showSuccess("Ο νέος εξοπλισμός εισάχθηκε επιτυχώς!");
        }

        setFileData(null);
      },
    },
  });
  const availableTypesOptions = equipmentTypes?.items.map((type) => ({
    id: type.id,
    label: type.name,
  }));

  const handleErrorsModalClose = () => {
    setErrorsModalOpen(false);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const adjustedFileData = fileData?.map((item) => ({
    name: item["Όνομα"] || "",
    floor: item["Όροφος"] || "",
    description: item["Περιγραφή"] || "",
    equipmentClassName: item["Κλάση"] || "",
    equipmentGroupName: item["Ομάδα"] || "",
    equipmentTypeName: item["Τύπος"] || "",
    assetId: selectedAsset?.id || "",
    typeOfSupply: item["Τύπος προμήθειας"] || "",
  }));

  const handleSubmit = () => {
    if (fileData && !validationErrors) {
      const payload = adjustedFileData;
      setSubmitData(payload);
      importBulkEquipment(payload);
    }
  };

  const handleFileUpload = (data) => {
    setFileData(data);
    setValidationErrors(null);
  };

  const handleValidationErrors = (errors) => {
    setValidationErrors(errors);
  };

  const handleFileDataUpdate = (updatedFileData) => {
    setFileData(updatedFileData);
  };

  const handleExport = useCallback((type) => {
    setSelectedExportType(type);
  }, []);

  const handleDownloadSample = useCallback(() => {
    if (!templateData) return;
    const url = window.URL.createObjectURL(new Blob([templateData]));
    const link = document.createElement("a");
    link.href = url;
    link.download = `sample.xlsx`;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    setSelectedExportType(null);
  }, [templateData]);

  useEffect(() => {
    if (selectedExportType) {
      refetchTemplateData();
    }
  }, [selectedExportType]);

  useEffect(() => {
    if (selectedExportType && templateData) {
      handleDownloadSample();
    }
    if (templateError) {
      showError("Παρουσιάστηκε πρόβλημα κατά την εξαγωγή του αρχείου.");
    }
  }, [templateData, selectedExportType, templateError, handleDownloadSample]);

  const disabledSubmitButton = !!validationErrors;
  const disabledNextButton = !fileData;
  const filteredFileData = (data) => {
    return data?.filter((row) => !Object.values(row)?.includes("Επιλέξτε"));
  };

  const steps = [
    {
      label: "Μεταφόρτωση Αρχείου",
      component: (
        <UploadFileStep
          onUpload={handleFileUpload}
          selectedEquipmentTypeName={selectedEquipmentTypeName}
          selectedAssetName={selectedAssetName}
        />
      ),
    },
    {
      label: "Επικύρωση Δεδομένων",
      component: (
        <DataValidationStep
          fileData={fileData ? filteredFileData(fileData) : []}
          onValidationErrors={handleValidationErrors}
          onFileDataUpdate={handleFileDataUpdate}
          handleCloseUploadModal={handleCloseUploadModal}
        />
      ),
    },
  ];

  if (equipmentTypesLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <SpinningCircle />
      </Box>
    );
  }

  if (equipmentTypesError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error:
          {equipmentTypesError?.message}
        </p>
      </Box>
    );
  }

  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"} gap={2}>
      <Typography color={colors.primary} fontWeight={700} fontSize={24} mb={2}>
        Μαζική Προσθήκη
      </Typography>

      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
        justifyContent={"flex-start"}
        alignItems="flex-end"
        width={"100%"}
      >
        <Box width={{ xs: "100%", md: "50%", lg: "30%" }}>
          <DropdownField
            label="Επιλογή Τύπου Εξοπλισμού"
            options={availableTypesOptions}
            value={selectedEquipmentType}
            onChange={(_, value) => setSelectedEquipmentType(value)}
          />
        </Box>
      </Box>

      {selectedEquipmentType && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={2}
          justifyContent={"flex-start"}
          alignItems="flex-start"
          width={"100%"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            justifyContent={"flex-start"}
            alignItems="flex-start"
            width={"100%"}
          >
            <Box
              display={"flex"}
              flexDirection={{ xs: "column", sm: "row" }}
              gap={1}
              justifyContent={"flex-start"}
              alignItems="baseline"
              width={"100%"}
              flexWrap={"wrap"}
            >
              <Typography color={colors.primary} fontWeight={700} fontSize={18}>
                Βήμα 1
              </Typography>
              <Typography color={colors.text} fontWeight={400} fontSize={14}>
                Κατεβάστε το πρότυπο αρχείο και συμπληρώστε τα δεδομένα
              </Typography>
            </Box>
            <CustomButton
              title="Λήψη πρότυπου αρχείου"
              orientation="row"
              fontSize={14}
              fontWeight={600}
              sx={{
                borderRadius: 3,
                paddingY: 1.8,
                width: { xs: "100%", md: 300 },
              }}
              onClick={() => handleExport("excel")}
              disabled={templateLoading || templateError}
              isLoading={templateLoading}
              icon={<FileDownloadOutlinedIcon />}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1}
            justifyContent={"flex-start"}
            alignItems="flex-start"
            width={"100%"}
          >
            <Box
              display={"flex"}
              flexDirection={{ xs: "column", sm: "row" }}
              gap={1}
              justifyContent={"flex-start"}
              alignItems="baseline"
              width={"100%"}
              flexWrap={"wrap"}
            >
              <Typography color={colors.primary} fontWeight={700} fontSize={18}>
                Βήμα 2
              </Typography>
              <Typography color={colors.text} fontWeight={400} fontSize={14}>
                Μεταφορτώστε το αρχείο και επικυρώστε τα δεδομένα
              </Typography>
            </Box>

            <CustomButton
              title="Μεταφόρτωση αρχείου και εισαγωγή δεδομένων"
              orientation="row"
              fontSize={14}
              fontWeight={600}
              sx={{
                borderRadius: 3,
                paddingY: 1.8,
                width: { xs: "100%", md: 300 },
              }}
              onClick={handleOpenUploadModal}
              icon={<FileUploadOutlinedIcon />}
            />
          </Box>
        </Box>
      )}

      <Box>
        <MassImportStepperModal
          isOpen={isUploadModalOpen}
          handleClose={handleCloseUploadModal}
          steps={steps}
          handleSubmit={handleSubmit}
          disabledSubmitButton={disabledSubmitButton}
          disabledNextButton={disabledNextButton}
          filteredFileData={filteredFileData(fileData)}
          setFileData={setFileData}
          warningTitle={warningTitle}
        />

        <EquipmentMassImportErrorModal
          modalOpen={errorsModalOpen}
          handleModalClose={handleErrorsModalClose}
          modalTitle={errorsModalTitle}
          errorsResponse={errorsResponse || []}
          fileData={submitData || []}
        />
      </Box>
    </Box>
  );
};

export default EquipmentMassImport;
