const ApplicationEntityState = [
  { id: 0, label: "Νέο" },
  { id: 1, label: "Τροποποιήθηκε" },
  { id: 2, label: "Σε εξέλιξη" },
  { id: 3, label: "Σε εκρεμμότητα" },
  { id: 4, label: "Ολοκληρώθηκε" },
  { id: 5, label: "Ακυρώθηκε" },
  { id: 6, label: "Διαγράφηκε" },
  { id: 7, label: "Δεν πραγματοποιήθηκε" },
];

const AssetUse = [
  { id: "Municipal", label: "Δημοτική χρήση" },
  { id: "Social", label: "Κοινωνική χρήση" },
  { id: "Commercial", label: "Εμπορική χρήση" },
  { id: "Other", label: "Άλλη χρήση" },
];

const AssetControls = [
  { id: 0, description: "Νομικά" },
  { id: 1, description: "Οικονομικά" },
  { id: 2, description: "Τεχνικά" },
  { id: 3, description: "Ενεργειακά" },
];

const Combustible = [
  { id: "Petrol", label: "Πετρέλαιο" },
  { id: "NaturalGas", label: "Φυσικό αέριο" },
  { id: "ElectricalEnergy", label: "Ηλεκτρικό ρεύμα" },
  { id: "Other", label: "Άλλο" },
];

const EconomicalControlNotesOptions = [
  { id: "ContractTermination", label: "Καταγγελίες συμβάσεων" },
  { id: "LeasesAdjustement", label: "Αναπροσαρμογή μισθώσεων" },
  { id: "Evictions", label: "Εξώσεις/ εκτέλεση αποφάσεων" },
  { id: "Other", label: "Άλλο" },
];

const EquipmentInUseStatus = [
  { id: 0, label: "Ενεργός" },
  { id: 1, label: "Ανενεργός" },
  { id: 2, label: "Σε Βλάβη" },
];

const EquipmentStatus = [
  { id: 0, label: "Διαθέσιμος" },
  { id: 1, label: "Προγραμματισμένος" },
  { id: 2, label: "Χρησιμοποιούμενος" },
  { id: 3, label: "Προς Μεταφορά" },
  { id: 4, label: "Σε Απόσυρση" },
];

const LegalControlNotesOptions = [
  { id: "Conflicts", label: "Αμφισβητήσεις" },
  { id: "Trespassing", label: "Καταπατήσεις" },
  { id: "Expropriations", label: "Απαλλοτριώσεις" },
  { id: "Other", label: "Άλλο" },
];

const Ownership = [
  { id: "Proprietary", label: "Ιδιοκτησία" },
  { id: "Rental", label: "Με ενοίκιο" },
];

const CompanyTypes = [
  { id: "Maintenance", label: "Συντήρηση" },
  { id: "Rent", label: "Ενοικίαση" },
  { id: "Acquisition", label: "Προμήθεια" },
];

const RentTypes = [
  { id: "Debit", label: "Πληρωμή" },
  { id: "Credit", label: "Είσπραξη" },
];

const ContractTypes = [
  { id: "Maintenance", label: "Συντήρηση" },
  { id: "Rent", label: "Ενοικίαση" },
  { id: "Acquisition", label: "Προμήθεια" },
];

const UserStatuses = [
  { id: "Active", label: "Ενεργός" },
  { id: "Inactive", label: "Ανενεργός" },
];

const ContractStatuses = [
  { id: "Active", label: "Ενεργή" },
  { id: "Inactive", label: "Ανενεργή" },
  { id: "Expired", label: "Έληξε" },
];

const FacilityTypes = [
  { id: "Asset", label: "Ακίνητο" },
  { id: "Equipment", label: "Εξοπλισμός" },
];

const Roles = {
  SuperAdmin: "SuperAdmin",
  Admin: "Admin",
  Manager: "Manager",
  User: "User",
  TechnicalIssues: "TechnicalIssues",
};

const TicketStatus = {
  Pending: "Σε εκκρεμότητα",
  Completed: "Ολοκληρώθηκε",
  Rejected: "Απορρίφθηκε",
};

const TaskStatuses = [
  { id: "Νέο", label: "Νέο" },
  { id: "Εκκρεμεί", label: "Εκκρεμεί" },
  { id: "Ολοκληρωμένο", label: "Ολοκληρωμένο" },
];

const ReportReferences = [
  { id: "Assets", label: "Ακίνητα" },
  { id: "Companies", label: "Οργανισμοί" },
  { id: "Tickets", label: "Τεχνικά Αιτήματα" },
  { id: "Tasks", label: "Εργασίες" },
  { id: "Contracts", label: "Συμβάσεις" },
];

const CertificationStatus = {
  Active: "Ενεργή",
  Inactive: "Ανενεργή",
  Expired: "Ληγμένη",
};

const MaintenanceStatus = {
  Active: "Ενεργή",
  Inactive: "Ανενεργή",
};

const CityPlanningCharacter = [
  { id: "Integral", label: "Άρτιο" },
  { id: "OffPlan", label: "Εκτός Σχεδίου" },
  { id: "InPlan", label: "Εντός Σχεδίου" },
  { id: "InsideTheSettlement", label: "Εντός Οικισμού" },
  { id: "NotIntegral", label: "Μη Άρτιο" },
  { id: "TraditionalSettlement", label: "Παραδοσιακός Οικισμός" },
  { id: "Designed", label: "Ρυμοτομούμενο" },
];

const OwnershipType = [
  { id: "RightOfInheritance", label: "Δικαίωμα Από Κληροδοσία" },
  { id: "RightOfServitude", label: "Δικαίωμα Δουλείας" },
  { id: "SurfaceRight", label: "Δικαίωμα Επιφάνειας" },
  { id: "RightToRise", label: "Δικαίωμα Υψούν" },
  {
    id: "RightOfExpectationOfOwnership",
    label: "Δικαίωμα Προσδοκίας Κυριότητας",
  },
  { id: "RightOfUse", label: "Επικαρπία" },
  { id: "Payment", label: "Μίσθωση" },
  { id: "Housing", label: "Οίκηση" },
  { id: "Allowance", label: "Παραχώρηση" },
  { id: "FullOwnership", label: "Πλήρης Κυριότητα" },
  { id: "FineOwnership", label: "Ψιλή Κυριότητα" },
];

const enums = {
  ApplicationEntityState,
  AssetUse,
  AssetControls,
  Combustible,
  EconomicalControlNotesOptions,
  EquipmentInUseStatus,
  EquipmentStatus,
  LegalControlNotesOptions,
  Ownership,
  CompanyTypes,
  UserStatuses,
  ContractStatuses,
  Roles,
  RentTypes,
  ContractTypes,
  TicketStatus,
  TaskStatuses,
  FacilityTypes,
  ReportReferences,
  CertificationStatus,
  MaintenanceStatus,
  CityPlanningCharacter,
  OwnershipType,
};

export default enums;
