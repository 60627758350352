import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useIsMutating } from "@tanstack/react-query";
import CancelButton from "components/buttons/CancelButton";
import DeleteButton from "components/buttons/DeleteButton";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import DateField from "components/inputs/DateField";
import DropdownField from "components/inputs/DropdownField";
import DeleteModal from "components/modals/DeleteModal";
import maintenanceResponseFields from "config/forms/maintenanceResponseFields";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useGetAllMaintenanceTypes } from "hooks/queries/useMaintenanceType";
import { useGetAllContracts } from "hooks/queries/useContracts";
import SpinningCircle from "components/spinners/SpinningCircle";
import { useState } from "react";
import AlfrescoFileUploader from "components/fileUploaders/AlfrescoFileUploader";
import enums from "config/enums";

const MaintenanceViewEditForm = ({
  selectedMaintenance,
  isEditing,
  onSave,
  onCancel,
  onDelete,
  selectedAsset,
  selectedEquipment,
  facilityType,
}) => {
  const {
    data: maintenancesTypes,
    isLoading: isMaintenancesTypesLoading,
    isError: isMaintenancesTypesError,
    error: errorMaintenancesTypes,
  } = useGetAllMaintenanceTypes({
    assetCategoryId:
      facilityType === "asset" ? selectedAsset?.assetCategory?.id : null,
    equipmentTypeIds:
      facilityType === "equipment"
        ? [selectedEquipment?.equipmentClass?.equipmentGroup?.equipmentType?.id]
        : null,
  });

  const maintenanceStatus = Object?.entries(enums.MaintenanceStatus)?.map(
    ([key, value]) => ({
      id: key,
      label: value,
    })
  );

  const {
    data: contracts,
    isLoading: isContractsLoading,
    isError: isContractsError,
    error: errorContracts,
  } = useGetAllContracts({
    status: ["Active"],
    serviceType: ["Maintenance"],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isUpdateLoading =
    useIsMutating({ mutationKey: ["updateMaintenance"] }) +
      useIsMutating({ mutationKey: ["uploadFiles"] }) +
      useIsMutating({ mutationKey: ["deleteMultipleFiles"] }) +
      useIsMutating({ mutationKey: ["deleteFile"] }) >
    0;
  const isDeleteLoading =
    useIsMutating({ mutationKey: ["deleteMaintenance"] }) > 0;

  const schema = yup.object(
    maintenanceResponseFields.maintenance.reduce((acc, field) => {
      let fieldSchema;

      if (field.type === "text" || field.type === "textarea") {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        if (field.name === "contractId") {
          fieldSchema = yup.object().shape({
            id: yup.string(),
            label: yup.string(),
          });
        } else if (field.name === "status") {
          fieldSchema = yup.object().shape({
            id: yup.string(),
            label: yup.string(),
          });
        } else {
          fieldSchema = yup.string();
        }
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          return isNaN(Date.parse(originalValue)) ? undefined : value;
        });
      } else if (field.type === "file") {
        const validTypes = field.validTypes || [];
        const maxSize = field.maxSize || 5000000;

        fieldSchema = yup.mixed().test({
          name: "fileValidation",
          exclusive: true,
          message: "Invalid file",
          test: (value) => {
            if (!value || value.length === 0) return true;

            for (const file of value) {
              if (
                !validTypes.includes(file.type) &&
                !validTypes.includes(file.mimeType)
              ) {
                return new yup.ValidationError(
                  "Μη έγκυρος τύπος αρχείου",
                  null,
                  "file"
                );
              }

              if (file.size > maxSize) {
                return new yup.ValidationError(
                  `Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα ${maxSize / 1000000}MB`,
                  null,
                  "file"
                );
              }
            }

            return true;
          },
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const getDataValueFromField = (fieldName) => {
    const fieldMap = maintenanceResponseFields.maintenance.reduce(
      (acc, field) => {
        acc[field.name] = field.name;
        return acc;
      },
      {}
    );

    const path = fieldMap[fieldName];
    if (typeof path === "function") {
      return path();
    } else if (typeof path === "string") {
      const value = path
        .split(".")
        .reduce(
          (acc, key) => (acc ? acc[key] : undefined),
          selectedMaintenance
        );
      return value !== undefined && value !== null ? value : null;
    } else {
      return "";
    }
  };

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formFields = maintenanceResponseFields.maintenance;

  const defaultValues = {
    ...Object.fromEntries(
      formFields.map((field) => [field.name, getDataValueFromField(field.name)])
    ),
    maintenanceTypeId: selectedMaintenance?.maintenanceType?.name,
    contractId: {
      id: selectedMaintenance?.contract?.id,
      label: selectedMaintenance?.contract?.name,
    },
    status: maintenanceStatus?.find(
      (status) => status?.id === selectedMaintenance?.status
    ),
    files: getDataValueFromField("files")?.map((file) => ({
      id: file.id,
      name: file.name,
      preview: file.url,
      type: file.mimeType,
      title: file.title,
    })),
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  if (isMaintenancesTypesLoading || isContractsLoading) {
    return <SpinningCircle />;
  }

  if (isMaintenancesTypesError || isContractsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <p>
          Error:
          {errorMaintenancesTypes?.message || errorContracts?.message}
        </p>
      </Box>
    );
  }

  const getInputElementByFieldType = (field) => {
    const defaultValue = getDataValueFromField(field.name);

    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea"
    ) {
      return (
        <CustomTextField
          {...field}
          value={defaultValue}
          control={control}
          disabled={!isEditing}
        />
      );
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      if (field.name === "maintenanceTypeId") {
        availableOptions = maintenancesTypes?.items?.map(
          (option) => option.name
        );
      }
      if (field.name === "contractId") {
        availableOptions = contracts?.items?.map((option) => ({
          id: option.id,
          label: option.name,
        }));
      }

      if (field.name === "status") {
        availableOptions = maintenanceStatus;
      }

      return (
        <DropdownField
          {...field}
          control={control}
          value={defaultValue}
          options={availableOptions}
          boolean={field.type === "boolean"}
          disabled={!isEditing}
          onChange={(e) => {
            if (field.name === "maintenanceTypeId") {
              setValue("contractId", null);
            }
          }}
        />
      );
    } else if (field.type === "date") {
      return (
        <DateField
          {...field}
          disabled={!isEditing}
          value={defaultValue}
          control={control}
        />
      );
    } else if (field.type === "file") {
      return (
        <AlfrescoFileUploader
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
        />
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Grid container spacing={2}>
          {formFields
            .filter((field) => field.display !== false)
            .map((field) => {
              if (field.type === "file") {
                return (
                  <Grid item xs={12} key={field?.name}>
                    {getInputElementByFieldType(field)}
                  </Grid>
                );
              } else
                return (
                  <Grid item xs={12} md={6} key={field.name}>
                    {getInputElementByFieldType(field)}
                  </Grid>
                );
            })}
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Box display="flex" gap={2} justifyContent={"flex-start"}>
            <CancelButton
              disabled={!isEditing}
              onClick={() => {
                reset();
                onCancel();
              }}
            />
            <DeleteButton
              onClick={handleOpenModal}
              text="Διαγραφή Συντήρησης"
              disabled={!isEditing}
              isLoading={isDeleteLoading}
            />
          </Box>

          <SaveButton
            disabled={!isEditing || !isDirty}
            isLoading={isUpdateLoading}
          />

          <DeleteModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onDelete={onDelete}
            itemTitle={selectedMaintenance?.name}
            title="Πρόκειται να διαγράψετε την συντήρηση"
            description="Είστε βέβαιοι ότι θέλετε να προχωρήσετε στη διαγραφή της συντήρησης;"
          />
        </Box>
      </Box>
    </form>
  );
};

MaintenanceViewEditForm.propTypes = {
  selectedMaintenance: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  selectedAsset: PropTypes.object,
  selectedEquipment: PropTypes.object,
  facilityType: PropTypes.oneOf(["equipment", "asset"]),
};

export default MaintenanceViewEditForm;
