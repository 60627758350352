import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Typography } from "@mui/material";
import { useIsMutating } from "@tanstack/react-query";
import CancelButton from "components/buttons/CancelButton";
import CustomButton from "components/buttons/CustomButton";
import SaveButton from "components/buttons/SaveButton";
import CustomTextField from "components/inputs/CustomTextField";
import DateField from "components/inputs/DateField";
import DropdownField from "components/inputs/DropdownField";
import AlfrescoFileUploader from "components/fileUploaders/AlfrescoFileUploader";
import TicketTaskModal from "components/modals/TicketTaskModal";
import enums from "config/enums";
import ticketResponseFields from "config/forms/ticketResponseFields";
import colors from "config/theme/colors";
import { useGetUserByEmail } from "hooks/queries/useUsers";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useAuthStore } from "stores/AuthStore";
import * as yup from "yup";

const TicketViewEditForm = ({
  selectedTicket,
  isEditing = false,
  onSave,
  onCancel,
  onComplete,
  onReject,
}) => {
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const isUpdateLoading =
    useIsMutating({ mutationKey: ["updateTicket"] }) +
      useIsMutating({ mutationKey: ["uploadFiles"] }) +
      useIsMutating({ mutationKey: ["deleteMultipleFiles"] }) +
      useIsMutating({ mutationKey: ["deleteFile"] }) >
    0;
  const { data: createdByUser } = useGetUserByEmail(selectedTicket.createdBy, {
    enabled: !!selectedTicket.createdBy,
    select: (data) => data.user,
  });
  const { data: completedByUser } = useGetUserByEmail(
    selectedTicket.completedBy,
    {
      enabled: !!selectedTicket.completedBy,
      select: (data) => data.user,
    }
  );

  const schema = yup.object(
    ticketResponseFields.reduce((acc, field) => {
      let fieldSchema;

      if (field.type === "text" || field.type === "textarea") {
        fieldSchema = yup.string();
      } else if (field.type === "select" || field.type === "boolean") {
        fieldSchema = yup.string();
      } else if (field.type === "number") {
        fieldSchema = yup
          .number()
          .transform((value) => (isNaN(value) ? undefined : value));
      } else if (field.type === "date") {
        fieldSchema = yup.date().transform((value, originalValue) => {
          return isNaN(Date.parse(originalValue)) ? undefined : value;
        });
      } else if (field.type === "file") {
        const validTypes = field.validTypes || [];
        const maxSize = field.maxSize || 5000000;

        fieldSchema = yup.mixed().test({
          name: "fileValidation",
          exclusive: true,
          message: "Invalid file",
          test: (value) => {
            if (!value || value.length === 0) return true;

            for (const file of value) {
              if (
                !validTypes.includes(file.type) &&
                !validTypes.includes(file.mimeType)
              ) {
                return new yup.ValidationError(
                  "Μη έγκυρος τύπος αρχείου",
                  null,
                  "file"
                );
              }

              if (file.size > maxSize) {
                return new yup.ValidationError(
                  `Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα ${maxSize / 1000000}MB`,
                  null,
                  "file"
                );
              }
            }

            return true;
          },
        });
      }

      if (field.required) {
        fieldSchema = fieldSchema.required("Το πεδίο είναι υποχρεωτικό");
      } else {
        fieldSchema = fieldSchema.nullable();
      }

      if (field.validation === "custom" && field.regex) {
        fieldSchema = fieldSchema.matches(field.regex, field.validationMessage);
      }

      acc[field.name] = fieldSchema.typeError(
        field.validationMessage || "Μη έγκυρη τιμή"
      );

      return acc;
    }, {})
  );

  const getDataValueFromField = useCallback(
    (fieldName) => {
      const fieldMap = ticketResponseFields.reduce((acc, field) => {
        acc[field.name] = field.name;
        return acc;
      }, {});

      const path = fieldMap[fieldName];
      if (typeof path === "function") {
        return path();
      } else if (typeof path === "string") {
        const value = path
          .split(".")
          .reduce((acc, key) => (acc ? acc[key] : undefined), selectedTicket);
        return value !== undefined && value !== null ? value : null;
      } else {
        return "";
      }
    },
    [selectedTicket]
  );

  const handleFormSubmit = (data) => {
    onSave(data);
  };

  const handleOpenCompleteModal = () => {
    setIsCompleteModalOpen(true);
  };

  const handleOpenRejectModal = () => {
    setIsRejectModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsCompleteModalOpen(false);
    setIsRejectModalOpen(false);
  };

  const formFields = ticketResponseFields;

  const isPendingStatus = selectedTicket.status === "Pending";

  const isUserEligibleToPerformAction =
    authStore.isSuperAdmin || authStore.isTechnicalIssues;

  const isSuperAdmin = authStore.isSuperAdmin;

  const defaultValues = useMemo(() => {
    return {
      ...Object.fromEntries(
        formFields.map((field) => [
          field.name,
          getDataValueFromField(field.name),
        ])
      ),
      status: enums.TicketStatus[getDataValueFromField("status")],
      completedBy: completedByUser
        ? `${completedByUser?.name} ${completedByUser?.surname}`
        : null,
      createdBy: createdByUser
        ? `${createdByUser?.name} ${createdByUser?.surname}`
        : null,
      files: getDataValueFromField("files")?.map((file) => ({
        id: file.id,
        name: file.name,
        preview: file.url,
        type: file.mimeType,
        title: file.title,
      })),
    };
  }, [createdByUser, completedByUser, formFields, getDataValueFromField]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const getInputElementByFieldType = (field) => {
    const defaultValue = getDataValueFromField(field.name);

    if (
      field.type === "text" ||
      field.type === "number" ||
      field.type === "textarea"
    ) {
      if (field.name === "createdBy" || field.name === "completedBy") {
        return (
          <CustomTextField
            {...field}
            disabled
            control={control}
            isLink={isSuperAdmin && !!defaultValue}
            onClick={
              isSuperAdmin && !!defaultValue
                ? () => {
                    if (field.name === "createdBy" && createdByUser) {
                      navigate(`/users/${createdByUser.id}`);
                    } else if (
                      field.name === "completedBy" &&
                      completedByUser
                    ) {
                      navigate(`/users/${completedByUser.id}`);
                    }
                  }
                : null
            }
          />
        );
      }

      return (
        <CustomTextField {...field} disabled={!isEditing} control={control} />
      );
    } else if (field.type === "select" || field.type === "boolean") {
      let availableOptions =
        field.type === "boolean" ? ["Ναι", "Όχι"] : field.options;

      return (
        <DropdownField
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
          options={availableOptions}
          boolean={field.type === "boolean"}
        />
      );
    } else if (field.type === "date") {
      return (
        <DateField
          {...field}
          value={defaultValue}
          disabled={!isEditing}
          control={control}
        />
      );
    } else if (field.type === "file") {
      return (
        <AlfrescoFileUploader
          {...field}
          control={control}
          value={defaultValue}
          disabled={!isEditing}
        />
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(() => handleFormSubmit(getValues()))}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Grid container spacing={2}>
          {formFields
            .filter((field) => field.display !== false)
            .map((field) => {
              if (field.type === "file") {
                return (
                  <Grid item xs={12} key={field?.name}>
                    <Box width={{ xs: "100%", lg: "50%" }}>
                      {getInputElementByFieldType(field)}
                    </Box>
                  </Grid>
                );
              } else
                return (
                  <Grid item xs={12} md={4} key={field?.name}>
                    {getInputElementByFieldType(field)}
                  </Grid>
                );
            })}
        </Grid>

        {Object.keys(errors).length > 0 && (
          <Typography color="error" fontSize={14}>
            Παρακαλώ ελέγξτε ότι όλα τα πεδία έχουν συμπληρωθεί σωστά
          </Typography>
        )}

        {isPendingStatus && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" gap={2} justifyContent={"flex-start"}>
              <CancelButton
                disabled={!isEditing}
                onClick={() => {
                  reset();
                  onCancel();
                }}
              />
            </Box>

            <Box
              display="flex"
              gap={2}
              justifyContent={"flex-end"}
              alignItems="center"
            >
              {isUserEligibleToPerformAction && (
                <>
                  <CustomButton
                    title="Ολοκλήρωση"
                    onClick={handleOpenCompleteModal}
                    fontSize={12}
                    fontWeight={700}
                    sx={{
                      paddingX: "22px",
                      paddingY: "12px",
                      borderRadius: 2,
                      backgroundColor: colors.success,
                      "&:hover": {
                        backgroundColor: colors.success,
                        opacity: 0.8,
                        color: colors.white,
                      },
                    }}
                  />
                  <CustomButton
                    title="Απόρριψη"
                    onClick={handleOpenRejectModal}
                    fontSize={12}
                    fontWeight={700}
                    sx={{
                      paddingX: "22px",
                      paddingY: "12px",
                      borderRadius: 2,
                      backgroundColor: colors.error,
                      "&:hover": {
                        backgroundColor: colors.error,
                        opacity: 0.8,
                        color: colors.white,
                      },
                    }}
                  />
                </>
              )}

              {(authStore.isUser ||
                authStore.isAdmin ||
                authStore.isSuperAdmin) && (
                <SaveButton
                  disabled={!isEditing || !isDirty}
                  isLoading={isUpdateLoading}
                />
              )}
            </Box>
          </Box>
        )}

        <TicketTaskModal
          isOpen={isCompleteModalOpen || isRejectModalOpen}
          onClose={handleCloseModal}
          onAction={isCompleteModalOpen ? onComplete : onReject}
          itemTitle={selectedTicket.name}
          actionButtonTitle={isCompleteModalOpen ? "Ολοκλήρωση" : "Απόρριψη"}
          actionButtonColor={
            isCompleteModalOpen ? colors.success : colors.error
          }
          title={
            isCompleteModalOpen ? "Ολοκλήρωση Αιτήματος" : "Απόρριψη Αιτήματος"
          }
          description={
            isCompleteModalOpen
              ? "Είστε σίγουρος ότι θέλετε να ολοκληρώσετε το αίτημα;"
              : "Είστε σίγουρος ότι θέλετε να απορρίψετε το αίτημα;"
          }
        />
      </Box>
    </form>
  );
};

TicketViewEditForm.propTypes = {
  selectedTicket: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  onReject: PropTypes.func,
};

export default TicketViewEditForm;
