import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import colors from "config/theme/colors";
import PropTypes from "prop-types";
import { Box, Modal, DialogActions, Tooltip, Typography } from "@mui/material";
import CustomButton from "components/buttons/CustomButton";
import DataGridTable from "components/tables/DataGridTable";
import enums from "config/enums";

const AssetMassImportErrorModal = ({
  modalOpen,
  handleModalClose,
  modalTitle,
  errorsResponse,
  fileData,
  assetCategories,
}) => {
  const columns = [
    {
      field: "name",
      headerName: "Όνομα",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.name ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "kaek",
      headerName: "ΚΑΕΚ",
      width: 150,
      editable: false,
      renderCell: (params) => {
        console.log("params", params);
        return (
          <Typography
            sx={{
              color: params.row.errors?.kaek ? "red" : "inherit",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              marginTop: 2,
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "floorCount",
      headerName: "Όροφοι",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.floorcount ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "city",
      headerName: "Πόλη",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.city ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "address",
      headerName: "Διεύθυνση",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.address ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "postalCode",
      headerName: "ΤΚ",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.postalcode ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "areaSize",
      headerName: "Εμβαδόν",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.areasize ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "ownership",
      headerName: "Ιδιοκτησία",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.ownership ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "assetUse",
      headerName: "Χρήση ακινήτου",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.assetuse ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {enums.AssetUse?.find((item) => item.id === params.value)?.label}
        </Typography>
      ),
    },
    {
      field: "assetCategoryId",
      headerName: "Κατηγορία",
      width: 150,
      editable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.errors?.assetcategory ? "red" : "inherit",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginTop: 2,
          }}
        >
          {
            assetCategories?.find((item) => item.id === params.value)
              ?.description
          }
        </Typography>
      ),
    },
  ];

  const errorsMap = errorsResponse?.reduce((acc, { index, errors }) => {
    acc[index] = errors?.reduce((errorAcc, field) => {
      errorAcc[field?.toLowerCase()] = true;
      return errorAcc;
    }, {});
    return acc;
  }, {});

  const rows = fileData
    ?.map((item, index) => ({
      id: index + 1,
      ...item,
      errors: errorsMap[index + 1] || {},
    }))
    ?.filter((row) => Object.keys(row.errors).length > 0);

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={modalOpen}
      onClose={handleModalClose}
      disableEscapeKeyDown
    >
      <Box
        sx={{
          backgroundColor: colors.white,
          borderRadius: "4px",
          minWidth: 600,
          maxHeight: "90vh",
          minHeight: 100,
          padding: 2,
        }}
        boxShadow={"0px 4px 4px #2948981A"}
        gap={2}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h3" color="error">
            {modalTitle}
          </Typography>

          <Box display="flex" justifyContent="end" borderRadius={"4px 4px 0 0"}>
            <Tooltip onClick={handleModalClose} style={{ cursor: "pointer" }}>
              <CloseIcon stroke={colors.primary} />
            </Tooltip>
          </Box>
        </Box>

        <Box
          sx={{
            marginTop: 4,
            minHeight: 200,
          }}
        >
          <DataGridTable
            key={JSON.stringify(rows)}
            columns={columns}
            rows={rows}
          />

          <Typography
            sx={{
              color: colors.text,
              marginTop: 2,
            }}
          >
            Οι παραπάνω εγγραφές δεν μπόρεσαν να εισαχθούν στο σύστημα. Παρακαλώ
            διορθώστε τα σφάλματα και προσπαθήστε ξανά.
          </Typography>

          <Typography
            fontSize={12}
            sx={{
              color: colors.text,
              marginTop: 2,
            }}
          >
            Εάν το πρόβλημα επιμένει ή δεν μπορείτε να το αντιμετωπίσετε,
            παρακαλούμε επικοινωνήστε με τον διαχειριστή του συστήματος.
          </Typography>
        </Box>

        <DialogActions>
          <CustomButton
            orientation="row"
            fontSize={14}
            fontWeight={600}
            sx={{
              minWidth: 120,
              maxHeight: 20,
              paddingY: 3,
              borderRadius: 3,
              marginTop: 3,
            }}
            title="OK"
            onClick={handleModalClose}
          />
        </DialogActions>
      </Box>
    </Modal>
  );
};

AssetMassImportErrorModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  errorsResponse: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
    })
  ).isRequired,
  fileData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AssetMassImportErrorModal;
